<template>
  <!--解除隔离弹窗-->
  <el-dialog v-if="isArrange" title="解除隔离" :visible.sync="isArrange" width="800px" :before-close="closeArrange">
    <div class="zctc">
      <el-form style="width:100%" :rules="rules" ref="redform" :model="zcredform" label-width="150px">

        <el-form-item label="转出类型：" prop="transferType">
          <el-radio-group @change="trasHome()" v-model="zcredform.transferType">
            <el-radio label="1">解除管控</el-radio>
            <el-radio label="2">送医治疗</el-radio>
            <!-- <el-radio :disabled="this..unselect==='3'" label="3">送往隔离点</el-radio> -->
            <el-radio label="4" :disabled="this.unselect==='4'">居家隔离</el-radio>
            <el-radio label="5" :disabled="this.unselect==='5'">居家监测</el-radio>
            <el-radio label="9">其他</el-radio>
          </el-radio-group>
        </el-form-item>

          <P style="color:red; margin-bottom: 20px;margin-left:100px" v-if="this.tipsList.length>0&&this.zcredform.transferType !== ''">{{this.tipsList.find(item =>{
            return item.transferType === this.zcredform.transferType
          }) ? this.tipsList.find(item =>{
           return item.transferType === this.zcredform.transferType
         }).tips : ''}}</P>

          <el-form-item label="转出目的地：" prop="">
            <span style="position: relative;color: red; top: 0px;left: -107px;">*</span>
            <!-- 如果没有回显的类型或者没有678类型的就默认显示一个 -->
            <el-input v-if="zcredform.transferType==''||zcredform.transferType==null||zcredform.transferType ==6||zcredform.transferType ==7||zcredform.transferType ==8" placeholder="请输入" />

            <!-- 选择类型为129的显示input -->
            <el-input v-if="zcredform.transferType == 1||zcredform.transferType ==2||zcredform.transferType ==9" style="width:90%" v-model="transferItem" placeholder="请输入" />

            <!-- 选择类型为4 5 的 是select框 -->
            <el-select v-if="zcredform.transferType == 4||zcredform.transferType ==5" clearable filterable size="medium" v-model="homeitem"
                @change="homeOrgChange($event)" placeholder="负责机构">
                <el-option v-for="(item,index) in homeList" :key="index" :label="item.orgName" :value="item.orgCode" />
            </el-select>

          </el-form-item>


        <el-form-item label="备注说明：" prop="remark">
          <el-input style="width:100%" v-model="zcredform.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button v-if="this.print ==''" size="small" @click="printNotification">打印解除隔离告知书</el-button>
      <el-button v-if="this.dispatch =='6'" size="small" @click="sendNotification">发送解除隔离告知书</el-button>
      <el-button size="small" @click="closeArrange">取 消</el-button>
      <el-button size="small" type="primary" @click="submitFrom">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  let nowtime ;
  import {
    downloadRelieveSignBook,
    relieveIsolate,
    sendRelieveSignBook,
  reliveControlTips
  } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';
  import { getRegLiveInfoApi } from '../../../api/Taskmanagement/upcomingTasks';
  import { getBusinessOrgList} from "../../../api/SystemManagement/index.js"; //根据业务状态查询机构
  import constants from "../../../common/constants";
  import moment from "moment";
  export default {
    name: "RelieveQuarantine",
    components: {},
    data() {
      return {
        transferItem:'',
        dispatch:"",
        print:"",
        sends:"",
        tipsList:[], //获取到的提示语数组
        select:"",
        unselect:"",
	      homeitem:"",
        homeList:[],
        isArrange: false,
        zcredform: {
          applyRelieveTime:"",
          transferType: '',
          transferDestination: '',
          remark: "",
          // transferDestinationCode:"" //根据居家来查找负责的社区结构
        },
        rules: {
          // applyRelieveTime:[{ required: true, message: "请选择解除隔离时间", trigger: "blur" }],
          transferType: [{ required: true, message: "请选择转出类型", trigger: "blur" }],
          transferItem: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
          homeitem: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
          remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
        },
        transfertypeselect: constants.TRANSFER_TYPE_SELECT, //转出类型
        regId: null,
        regIds: [],
        regTime: new Date()
      }
    },
  
    props: {
      // 此组件在多个组件中引用，该属性用于区别用于哪个组件
      inputType: {
        type: String,
        default: ""
      }
    },

   
  

    mounted(){
      
      // console.log('执行mounteddddddddddd')
    this.homeOrgList() //根据业务查询业务机构
    // this.controlTips()
  },
    
    methods: {
      async show(item) {
        
        // console.log(item,'打印任务管理，待办任务 接触隔离里面选中的人员信息')
        // 如果点的单个预解除隔离，那么就是regId，如果是选择批量预解除，就是regIds
        if(Array.isArray(item)) {
          if(item.length>1){
            this.regIds = item.map(item => item.id);
            this.print = '0'; //批量不显示打印告知书
            // this.sends = '0'; //批量不显示发送告知书
          }
          this.regIds = item.map(item => item.id);
        } else {
          // console.log(this.inputType,'打印inputype')
          // 单个人的时候，如果登记渠道为小程序才显示 发送隔离告知书
          this.dispatch = item.regChannel;
          this.regId = item.id;
           // // 查询提示语
        let params = {
            id:item.id,
            source: "1"
        }
        let res = await reliveControlTips ({params});
        // console.log(res,'打印reswwwwwww')
        this.tipsList = res.data.data.tips; //获取到的提示语数组
        this.unselect = res.data.data.unselect; //不选中类型
        this.select = res.data.data.select; //获取到的选中的转出类型
        // console.log(this.select,'this.select打印，有可能是数字，有可能为null')
        if(this.select!==null){
          this.zcredform.transferType = this.select
        }
        }
          this.isArrange = true;
          this.listItem = item;
          // this.regId = item.id;
          this.zcredform = {
            applyRelieveTime:"",
            transferType: '1',
            transferDestination: '',
            remark: "",
            // transferDestinationCode:"" //根据居家来查找负责的社区结构
          }

          // if (this.inputType === "待办任务") {
          //   this.getRegLiveInfo();
          // }
          this.getRegLiveInfo();
      },
      closeArrange() {
        this.isArrange = false;
      },

      


    // 清空选项
    trasHome(){
        this.homeitem = "";
        this.transferItem = "";
        this.zcredform.transferDestinationCode = "";
    },

     // 根据业务状态去查询机构
    async homeOrgList() {
      let params = { homeIsolation: 1 }
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.homeList = data.data
      }
    },

    homeOrgChange(orgCode){
      // console.log('lllll选择机构出发事件')
      // 获取当前下拉框选中的对象
      let dir = this.homeList.find((item) => item.orgCode === orgCode);
      // console.log(dir,'打印dirrrr')
      this.homeitem = dir.orgName;
      this.zcredform.transferDestinationCode = dir.orgCode;
    },

  //  // 清空后选项
  //  trasHome(){
  //       this.homeitem = "";
  //       this.transferItem = "";
  //   },
  
      async printNotification() {
        let params = {
          regId: this.regId
        };
        try {
          let { data } = await downloadRelieveSignBook({ params });
          let url = window.URL.createObjectURL(data);
          window.open(url);
        } catch (error) { }
      },
  
      async sendNotification() {
        let params = {
          regId: this.regId
        };
        try {
          let { data } = await sendRelieveSignBook({ params });
          this.$notify({
            title: "解除隔离告知书发送",
            message: `${data.msg}`,
            dangerouslyUseHTMLString: true
          });
          this.isArrange = false;
        } catch (error) { }
      },
  

      // 确定隔离
    
      async submitFrom() {
        let params = {
          ...this.zcredform,
          regId: this.regId,
          regIds: this.regIds.length > 0 ? this.regIds : null,
          transferDestination:this.homeitem==""? this.transferItem : this.homeitem,
        };
        let { data } = await relieveIsolate(params);
        if (data.code == 200) {

          this.$message({
            message:"解除隔离成功",
            type: "success"
            });
          this.isArrange = false;

          if (this.inputType === "待办任务") {
            //调用父组件的方法 但是未找到此方法，待解决
            this.$parent.queryDataInfo();
          } else if (this.inputType === "居民详情") {
            this.$parent.getIsolateDetailByRegId(this.listItem)
          }
        } else {
          this.$message.error(data.msg);
          this.isArrange = true;
          this.$message.warning("请把信息填写完整");
        }
      },
  

      // 数据回显
      getRegLiveInfo() {
        // 如果是单个点进来，那么要单个用户的数据回显
        if(this.regId!==null){
          let params = { regId: this.regId };
        getRegLiveInfoApi({ params }).then(res => {
          let { code, data, msg } = res.data;
          // console.log(data,'回显dataaaaaa')
          if (code == 200) {
            this.zcredform = {
              applyRelieveTime:"",
              transferType: data.transferType==null?this.select : data.transferType,
              // transferDestination: data.transferDestination,
              transferDestinationCode: data.transferDestinationCode==null? '':data.transferDestinationCode,
              remark: data.remark,
            }
            if(this.zcredform.transferType=='1'||this.zcredform.transferType=='2'||this.zcredform.transferType=='9'){
              this.transferItem =data.transferDestination  //回显input文本框
            }else{
              this.homeitem = data.transferDestination; //回显社区机构
            }
          } else {
            this.$message.error(msg)
          }
        })
        }
      },


    }
  }
  </script>
  
  <style scoped lang="scss">
  </style>
  
